<template>
  <gf-card>
    <el-dialog v-loading="removeModalLoading" :close-on-click-modal="false" :visible.sync="removeModalVisible"
      append-to-body title="Remove Sales Order">
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-danger svg-icon-xxl">
            <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <rect height="24" width="24" x="0" y="0" />
                <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7" />
                <rect fill="#000000" height="2" rx="1" width="2" x="11" y="16" />
              </g>
            </svg>
          </span>
        </template>
        You are attempting to delete a Sales Order. You will no longer be able to undo this. Are you sure?
      </gf-alert>
      <template #footer>
        <gf-button class="mr-2" type="secondary" @click="removeModalVisible = false">
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <g fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect height="2" opacity="0.3" rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16" x="0" y="7" />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="danger" @click="remove">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000" fill-rule="nonzero" />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog v-loading="voidModalLoading" :close-on-click-modal="false" :visible.sync="voidModalVisible" append-to-body
      title="Void Sales Order">
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-warning svg-icon-xxl">
            <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <rect height="24" width="24" x="0" y="0" />
                <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7" />
                <rect fill="#000000" height="2" rx="1" width="2" x="11" y="16" />
              </g>
            </svg>
          </span>
        </template>
        <span>Voiding this order will rollback any fulfillments and revert any stock movements. You will no longer be able
          to undo this.</span>
      </gf-alert>
      <template #footer>
        <gf-button class="mr-2" type="secondary" @click="voidModalVisible = false">
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <g fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect height="2" opacity="0.3" rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16" x="0" y="7" />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="warning" @click="voidSA">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000" fill-rule="nonzero" />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <gf-content v-loading="loading" overflow>
      <template #title>
        <div class="title">
          <span class="font-weight-normal mr-3">{{ so.customer.name }}</span>
          <gf-label v-if="so.status === 1" inline pill type="dark">Draft</gf-label>
          <gf-label v-if="so.status === 2" inline pill type="primary">Active</gf-label>
          <gf-label v-if="so.status === 3" inline pill type="success">Finalized</gf-label>
          <gf-label v-if="so.status === 4" inline pill type="info">Fulfilled</gf-label>
          <gf-label v-if="so.status === 5" inline pill type="warning">Void</gf-label>
          <gf-label v-if="so.status === 6" inline pill type="danger">Deleted</gf-label>
        </div>
      </template>
      <template #toolbar>
        <gf-button class="mr-4" size="sm" type="success" @click="print">Print</gf-button>
        <gf-button v-if="so.status === 1" class="mr-2" size="sm" type="secondary" @click="edit">Edit</gf-button>
        <gf-button v-if="so.status === 1 || so.status === 2" class="mr-2" size="sm" type="danger"
          @click="removeModalVisible = true">Delete</gf-button>
        <gf-button v-if="so.status === 3 || so.status === 4" size="sm" type="warning"
          @click="voidModalVisible = true">Void</gf-button>
      </template>
      <template #header>
        <el-row>
          <el-col :lg="8" :md="8" :sm="24">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Bill To</span>
                <span class="font-size-sm font-weight-normal pr-3">Ship To</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ so.bill_to.label }}</span>
                <span class="font-size-sm pl-3">{{ so.ship_to.label }}</span>
              </div>
            </div>
          </el-col>
          <el-col :lg="8" :md="8" :sm="24">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Issued Date</span>
                <span class="font-size-sm font-weight-normal pr-3">Shipment Date</span>
                <span class="font-size-sm font-weight-normal pr-3">Email</span>
                <span class="font-size-sm font-weight-normal pr-3">Phone</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ $DateFormat(so.issue_date) }}</span>
                <span class="font-size-sm pl-3">{{ $DateFormat(so.shipment_date) }}</span>
                <span class="font-size-sm pl-3">{{ so.email }}</span>
                <span class="font-size-sm pl-3">{{ so.phone }}</span>
              </div>
            </div>
          </el-col>
          <el-col :lg="8" :md="8" :sm="24">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Total are</span>
                <span class="font-size-sm font-weight-normal pr-3">Assigned To</span>
              </div>
              <div class="content">
                <span v-if="so.is_inclusive === 0" class="font-size-sm pl-3">Tax Exclusive</span>
                <span v-else-if="so.is_inclusive === 1" class="font-size-sm pl-3">Tax Inclusive</span>
                <span v-else class="font-size-sm pl-3">No Invoice</span>
                <span class="font-size-sm pl-3">{{ so.assigned_to.fname }} {{ so.assigned_to.lname }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
      <div>
        <gf-table :data="so.items" :scrollable="false">
          <el-table-column label="Item Name" min-width="180" prop="name">
            <template slot-scope="slot">
              <div class="gf-item">
                <div v-if="slot.row.variant.name === slot.row.product.name && slot.row.variant.is_pack === 0"
                  class="gf-item__name">{{ slot.row.variant.name }}</div>
                <div v-else-if="slot.row.variant.is_pack === 0" class="gf-item__name">{{ slot.row.product.name }} - {{
                  slot.row.variant.name }}</div>
                <div v-else class="gf-item__name">
                  <span v-if="slot.row.product.name === slot.row.variant.variant.name">{{ slot.row.variant.variant.name }}
                    - {{ slot.row.variant.name }}</span>
                  <span v-else>{{ slot.row.product.name }} - {{ slot.row.variant.variant.name }} - {{
                    slot.row.variant.name }}</span>
                  <span>(Pack of {{ slot.row.variant.pack_size }})</span>
                </div>
                <div class="gf-item__sub">
                  <span class="gf-item__sub__sku">SKU: {{ slot.row.variant.sku }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Quantity" min-width="110" prop="quantity">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.quantity) }}
            </template>
          </el-table-column>
          <el-table-column label="Available" min-width="110">
            <template slot-scope="slot">
              <span v-if="slot.row.variant.is_pack">{{ $NumberFormat((slot.row.stocks.find(st => st.warehouse_id ===
                so.ship_from_id).value - slot.row.stocks.find(st => st.warehouse_id === so.ship_from_id).commited) /
                parseFloat(slot.row.variant.pack_size)) }}</span>
              <span v-else>{{ $NumberFormat(slot.row.stocks.find(st => st.warehouse_id === so.ship_from_id).value -
                slot.row.stocks.find(st => st.warehouse_id === so.ship_from_id).commited) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Price" min-width="120" prop="cost">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.cost) }}
            </template>
          </el-table-column>
          <el-table-column label="Discount" min-width="110" prop="discount">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.discount) }}%
            </template>
          </el-table-column>
          <el-table-column v-if="so.is_inclusive !== 2" label="Tax" min-width="100" prop="tax">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.tax) }}%
            </template>
          </el-table-column>
          <el-table-column label="Total" min-width="100" prop="total">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.cost * slot.row.quantity - (slot.row.cost * slot.row.quantity * (slot.row.discount
                / 100))) }}
            </template>
          </el-table-column>
        </gf-table>
      </div>
      <div class="footer mt-2">
        <div class="note">
          <span>{{ so.message }}</span>
        </div>
        <div class="gf-summary">
          <ul class="summary">
            <li class="summary__item">
              <span class="text">Total Units</span>
              <span class="value">{{ $NumberFormat(so.units) }}</span>
            </li>
            <li v-if="so.is_inclusive !== 2" class="summary__item">
              <span class="text">Subtotal</span>
              <span class="value">{{ $NumberFormat(so.subtotal) }}</span>
            </li>
            <div v-if="so.is_inclusive !== 2">
              <li v-for="(vat, index) in so.vats" :key="index" class="summary__item">
                <span class="text">{{ (so.is_inclusive) ? 'Including' : 'Plus' }} VAT ({{ vat.rate }}%)</span>
                <span class="value">{{ $NumberFormat(vat.value) }}</span>
              </li>
            </div>
            <li class="summary__item">
              <span class="text">Total</span>
              <span class="value">{{ $NumberFormat(so.total) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </gf-content>
  </gf-card>
</template>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
}

.detail {
  display: flex;
  justify-content: center;

  .title,
  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    align-items: flex-end;

    span {
      border-right: 1px solid #ebedf3;
    }
  }

  .content {
    align-items: flex-start;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}
</style>

<script>
// Services
import SalesOrderService from '@/services/v1/SalesOrder'

export default {
  props: {
    update: Boolean,
    loaded: Boolean,
    updateChild: Boolean
  },
  data() {
    return {
      loading: false,

      so: {
        so_number: null,
        status: null,
        issued_date: null,
        shipment_date: null,
        email: null,
        phone: null,
        is_inclusive: 0,
        message: null,
        units: null,
        subtotal: null,
        vats: [],
        total: null,
        items: [],
        customer: {
          name: null
        },
        bill_to: {
          label: null
        },
        ship_to: {
          label: null
        },
        ship_from: {
          name: null
        },
        currency: {
          name: null
        },
        assigned_to: {
          fname: null,
          lname: null
        }
      },

      removeModalVisible: false,
      removeModalLoading: false,
      voidModalVisible: false,
      voidModalLoading: false
    }
  },
  watch: {
    updateChild: function () {
      if (this.updateChild) {
        this.$emit('update:updateChild', false)
        this.getSalesOrder()
      }
    }
  },
  methods: {
    print() {
      this.$router.push({ name: 'print-order', params: { id: this.$route.params.id } })
    },
    edit() {
      this.$router.push({ name: 'order-edit', params: { id: this.$route.params.id } })
    },
    async remove() {
      try {
        this.removeModalLoading = true

        const soService = new SalesOrderService(this.$route.params.id)
        await soService.del()
        this.$message.success(`${this.so.so_number} successfully deleted`)
        await this.getSalesOrder()

        this.$emit('update:update', true)
        this.removeModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.removeModalLoading = false
      }
    },
    async voidSA() {
      try {
        this.voidModalLoading = true

        const soService = new SalesOrderService(this.$route.params.id)
        await soService.void()
        this.$message.success(`${this.so.so_number} successfully voided`)
        await this.getSalesOrder()

        this.$emit('update:update', true)
        this.voidModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.voidModalLoading = false
      }
    },

    // Service Fetch
    async getSalesOrder() {
      try {
        this.loading = true
        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.get()
        this.so = result.data
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.getSalesOrder()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Sales Order', route: '' },
      { title: 'Order' },
      { title: this.so.so_number }
    ])
  }
}
</script>
